const chatbot = () => {
  return (
    <div className="w-screen h-screen">
      <iframe
        width="100%"
        height="100%"
        title="chatbot"
        src="https://bot-prd.bluelab.com.br/chat/public/chatbot?company=decathlon&alias=decathlon"
      />
    </div>
  )
}

export default chatbot
